

















































































































































































































































import { Vue } from 'vue-property-decorator';
import { clientService, healthdataService, fileService } from '../_services';
import { settings } from '@/variables';
import EventBus  from '@/variables';
import { Client } from '@/types';

// @Component
export default Vue.extend({
    name: "EditClient",
    props: {
        clientKey: String,
        isNew: Boolean,
    },
    data() {
        return {
            selectedClient: {} as Client,
            statusList: [
                {value: 'new', text: 'Neu'},
                {value: 'active', text: 'Aktiv'},
                {value: 'inactive', text: 'Inaktiv'},
                {value: 'cancelled', text: 'Gekündigt'},
            ],
            typesOfStay: [
                {value: 'EU Bürger', text: 'EU Bürger'},
                {value: 'Niederlassung', text: 'Niederlassung'},
                {value: 'Daueraufenthalt', text: 'Dauerauftenthalt'},
                {value: 'Daueraufenthalt EU', text: 'Dauerauftenthalt EU'},
                {value: 'Familienangehöriger', text: 'Familienangehöriger'},
                {value: 'Sonstiges', text: 'Sonstiges'},
                
            ],
            docTypes: [
                {value: 'RP', text: 'Reisepass'},
                {value: 'PA', text: 'Personalausweis'},
                {value: 'Sonstiges', text: 'Sonstiges'},
            ],
            imageSource: {
                pass_1:  "",
                key_pass_1: "",
                pass_2:  "",
                key_pass_2: "",
            },
            eventBus: EventBus,
            countryList: settings.countries,
            printApi: settings.printApi,
            healthdata: [],
            newhealthdate: [],
            healthDataTypes: [] as any[],
            newClientHealthData: {},
            currentFile: ""
        }
    },
    methods: {
        onFileSelected(event: any) {
            let files= event.target.files;
            this.readAsDataURL(files[0]).then(imagedata => {
                this.currentFile= String(imagedata);
                console.log("read file " + this.currentFile);
            });
        },
        isNewCustomer() {
            if (this.isNew) {
                return this.selectedClient._key == null || this.selectedClient._key == undefined;
            }
            return false;
        },
        deleteCustomer() {
            if (!confirm("Sind Sie sicher, dass Sie die KundIn löschen möchten?")) {
                return;
            }
            clientService.deleteClient(this.getClientKey()).then((data) => {
                console.log("Client deleted: ");
                if (data=='') {
                    alert("Die Kundin wurde gelöscht.");
                    window.history.go();

                }
            }).catch((e) => {
                alert("Fehler beim Löschen: " + e.errorMessage)
            });

        },
        saveData() {
            console.log("submitting data");
            const missing= this.missingFields();
            if (missing!='') {
                alert("Die Felder " + missing + " sind Pflichtangaben.");
                return;
            }
            if (this.selectedClient.artistName=='' || this.selectedClient.firstName=='' || this.selectedClient.lastName=='' || this.selectedClient.status=='' 
                || this.selectedClient.typeOfStay=='' )  {
                alert("Die Felder Familienname, Vorname, Künstlername, Aufenthaltstitel und Kundenstatus sind Pflichtangaben!")
                return;
            }
            let updatedClient= {} as Record<string, unknown>;
            // handle healthAuthority: 
            this.selectedClient.healthAuthority = this.selectedClient.healthBookIssuer;
            const request= JSON.parse(JSON.stringify(this.selectedClient));
            console.log("sending data: " + JSON.stringify(request));
            // request.additionalData= {};
            if (!this.isNewCustomer()) {
                clientService.updateClient(this.getClientKey(), request).then(data => {
                    console.log("received updated data :" + JSON.stringify(data));
                    if (data.error) {
                        alert("Fehler beim Speichern: " + data.errorMessage);
                    } else {
                        this.selectedClient= data;
                       this.eventBus.$emit("reloadCustomers", this.selectedClient.artistName);
                    }
                }).catch(exc => {
                    alert("Fehler beim Update: " + JSON.stringify(exc) + "\nPrüfen Sie die Pflichtfelder!");
                });
            } else {
                clientService.insertClient(request).then(data => {
                    console.log("received updated data :" + JSON.stringify(data));
                    if (data.error) {
                        alert(data.errorMessage);
                    } else {
                        this.takeClientData(data);
                       this.loadHealthData();
                       // this.eventBus.$emit("reloadCustomers", this.selectedClient.artistName);
                    }
                }).catch(exc => {
                    alert("Konnte KundIn nicht anlegen, Grund: " + exc + "\nPrüfen Sie die Pflichtfelder!");
                });
            }
            console.log("updated client with id: " + updatedClient.id);
        },
        takeClientData(newData: any) {
            this.selectedClient = newData;
            if (this.selectedClient.birthDay != null) { this.selectedClient.birthDay= this.selectedClient.birthDay.substring(0,10); }
            if (this.selectedClient.documentValidity != null) { this.selectedClient.documentValidity = this.selectedClient.documentValidity.substring(0,10); }
            if (this.selectedClient.documentIssueDate != null) { this.selectedClient.documentIssueDate = this.selectedClient.documentIssueDate.substring(0,10); }
            if (this.selectedClient.healthBookCreated != null) { this.selectedClient.healthBookCreated = this.selectedClient.healthBookCreated!=undefined?this.selectedClient.healthBookCreated.substring(0,10):""; }
        },
        submitHealthData() {
            console.log("submitting health data " );
            let number = 0;
            for (let hdt of this.healthDataTypes) {
                if (this.newhealthdate[hdt.value] != null) {
                    let date= this.newhealthdate[hdt.value];
                    console.log("date for type " + hdt.value + ":"  +  date);
                    const request= JSON.parse(JSON.stringify(this.newClientHealthData));
                    request._from= settings.dbschema+"_Client/"+this.getClientKey();
                    request._to = settings.dbschema+"_HealthData/"+hdt.value;
                    request.checkedAt = date;
                    number++;
                    healthdataService.insertClientHealthData(request).then(data => {
                        this.loadHealthData();
                    });
                }
            }
            console.log("stored " + number + " healthdata records")
  
        },
        missingFields() {
            let missingFields = '';
            if (this.selectedClient.artistName==null || this.selectedClient.artistName=='') {
                missingFields= "Künstlername";
            }
            if (this.selectedClient.firstName==null || this.selectedClient.firstName=='') {
                missingFields= missingFields+", Vorname";
            }
            if (this.selectedClient.lastName==null || this.selectedClient.lastName=='') {
                missingFields= missingFields+", Familienname";
            }
            if (this.selectedClient.status==null || this.selectedClient.status=='') {
                missingFields= missingFields+", Kundenstatus";
            }
            if (this.selectedClient.typeOfStay==null || this.selectedClient.typeOfStay=='') {
                missingFields= missingFields+", Aufenthaltstitel";
            }
            return missingFields;
        },
        loadHealthData() {
            if (this.getClientKey() === undefined || this.getClientKey() === null) {
                return;
            }
            healthdataService.getFromClient(this.getClientKey()).then(data => {
                this.healthdata= data.map((d: any) => {
                    let bgcolor= "info";
                    if (d.percent<10) bgcolor= "danger";
                    else if (d.percent<30) bgcolor= "warning";
                    let ckDat= new Date(d.checkedAt);
                    let properties = {
                        "name": d.name,
                        "percent": d.percent,
                        "renewalDays": d.renewalDays,
                        "daysLeft": d.daysLeft,
                        "checkedAt": d.checkedAt,
                        "checkDate": "" + ckDat.getDate() + "." + (ckDat.getMonth()+1) + ". " + ckDat.getFullYear(),
                        "color": bgcolor, 
                    };
                    console.log("color for " + d.percent + ": " + bgcolor);
                    return properties;
                });
                console.log("fetched healthdata");
                this.healthDataTypes= data.map((d: any) => {
                    let properties = {
                        "value": d._key,
                        "text": d.name
                    }
                    return properties;
                });
            });
        },
        loadClientImage(key: string) {
            if (this.getClientKey() === undefined || this.getClientKey() === null) {
                return;
            }
            fileService.getFromClient(this.getClientKey(), key).then(response => {
                if (response.data!="") {
                    console.log("received image data for key " + key);
                    if (key=="pass_1")
                        this.imageSource.pass_1= response.data;
                    else if (key=="pass_2") 
                        this.imageSource.pass_2 = response.data;
                }
            }).catch( e => {
                console.log('Error while reading image ' + key + ': ' + e);
            });
        },
        getClientKey() {
            if (this.selectedClient._key != null) {
                return this.selectedClient._key;
            }
            return this.clientKey;
        },
        removeImage(key: string) {
            fileService.removeImage(this.getClientKey(), key).then((result) => {
                if (key=='pass_1') {
                    this.imageSource.pass_1 = '';
                } else if (key=='pass_2') {
                    this.imageSource.pass_2 = '';
                }
            });
        },
        prepareUpload(files: any){
            console.log("preparing upload now " + JSON.stringify(files));
            console.log(files);
            this.readAsDataURL(files[0]).then(imagedata => {
                this.currentFile= String(imagedata);
            });
        },
        uploadImage(key: string) {
            console.log("uploading now");
            fileService.insertImage(this.getClientKey(), key, this.currentFile).then( () => {
                this.loadClientImage(key);
            });
        },
        readAsDataURL(file: any) {
            return new Promise(function (resolve, reject) {
                let fr = new FileReader();
                fr.onload = function () {
                    resolve(fr.result);
                };
                fr.onerror = function () {
                    reject(fr);
                };
                fr.readAsDataURL(file);
            });
        },
    },
    created() {
        if (!this.isNewCustomer()) {
            clientService.getById(this.getClientKey()).then((data: Client) => {
                this.takeClientData(data);
                console.log("editclient created, data: " + JSON.stringify(this.selectedClient));
            });
        }
        this.loadHealthData();
        this.loadClientImage('pass_1');
        this.loadClientImage('pass_2');
    }
});
